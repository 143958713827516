<template>
  <div>
    <div>Redis终端</div>
  </div>
</template>
<script>
export default {
  name: 'RedisTerminal'
}
</script>
<style>

</style>
